<template>
  <div class="bg-white">
    <div class="mx-auto px-4 pt-2 md:pt-6 lg:max-w-7xl lg:px-6">
      <!-- <portal-carousel :slides="slides" /> -->
      <home-hero title-hello="Szukasz niani?" title-for="Jesteś nianią?"
        >Morbi imperdiet felis at congue vulputate. Donec vitae viverra tellus,
        id consequat orci. Maecenas eget tincidunt leo d consequat orci Maecenas
        eget tincidunt leo.
        <template v-slot:buttons>
          <home-hero-btn to="/">Dla rodzica</home-hero-btn>
          <home-hero-btn to="/" light="light">Dla niani</home-hero-btn>
        </template>
      </home-hero>
      <portal-home-process />
    </div>
  </div>
  <div class="mx-auto py-6 px-4 lg:max-w-7xl lg:px-6">
    <portal-home-why />
  </div>
  <div class="bg-white">
    <div class="mx-auto py-6 px-4 lg:max-w-7xl lg:px-6">
      <portal-home-latest />
    </div>
  </div>
  <!-- <modal-introduce v-if="showModal" @close="closeModal" /> -->
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useGlobalsStore } from '@stores/globals'
// import { useRouter } from 'vue-router'
// import { useOverlayStore } from '@stores/overlay'
// import PortalCarousel from '@portal/PortalCarousel'
import PortalHomeProcess from '@portal/PortalHomeProcess'
import HomeHero from '@portal/HomeHero'
import HomeHeroBtn from '@portal/HomeHeroBtn'
import PortalHomeWhy from '@portal/PortalHomeWhy'
import PortalHomeLatest from '@portal/PortalHomeLatest'
// import ModalIntroduce from '@portal/ModalIntroduce'

// import bg1 from '@assets/carousel/1.jpg'
// import bg2 from '@assets/carousel/2.jpg'
// import bg3 from '@assets/carousel/3.jpg'

// import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    // PortalCarousel,
    PortalHomeProcess,
    PortalHomeWhy,
    PortalHomeLatest,
    HomeHero,
    HomeHeroBtn
    // ModalIntroduce
  },
  setup() {
    // const { t } = useI18n()
    const useGlobals = useGlobalsStore()
    useGlobals.updateNavbarData({
      to: '',
      toText: '',
      text: 'Strona główna'
    })
    // const showModal = ref(false)

    // const router = useRouter()
    // const useOverlay = useOverlayStore()
    // const closeModal = async () => {
    //   useOverlay.close()
    //   await nextTick()
    //   showModal.value = false
    // }
    // onMounted(async () => {
    //   if (router.options.history.state.back === '/auth') {
    //     showModal.value = true
    //     await nextTick()
    //     useOverlay.show('ModalIntroduce')
    //   } else {
    //     showModal.value = false
    //   }
    // })

    // const slides = [
    //   {
    //     id: 1,
    //     bg: bg1,
    //     title: t('carousel.slides.slide1.title'),
    //     description: t('carousel.slides.slide1.description'),
    //     btn: t('carousel.slides.slide1.btn'),
    //     link: t('carousel.slides.slide1.link')
    //   },
    //   {
    //     id: 2,
    //     bg: bg2,
    //     title: t('carousel.slides.slide2.title'),
    //     description: t('carousel.slides.slide2.description'),
    //     btn: t('carousel.slides.slide2.btn'),
    //     link: t('carousel.slides.slide2.link')
    //   },
    //   {
    //     id: 3,
    //     bg: bg3,
    //     title: t('carousel.slides.slide3.title'),
    //     description: t('carousel.slides.slide3.description'),
    //     btn: t('carousel.slides.slide3.btn'),
    //     link: t('carousel.slides.slide3.link')
    //   }
    // ]
    // return {
    // slides,
    // showModal,
    // closeModal
    // }
  }
})
</script>
