<template>
  <div v-if="shouldRender">
    <h5
      class="relative hidden rounded-t-lg border-x border-t bg-white py-4 px-8 text-xl text-secondary-500 lg:block"
    >
      {{ $t('sidebar.filters.searchFilters') }}
    </h5>
    <div v-if="isLoading">
      <attr-skeleton v-for="i in 6" :key="i" />
    </div>
    <div v-else>
      <attr-item-small
        v-for="filter in mainAttrs"
        :key="filter.id"
        :attr-type="isParent ? 'nanny' : 'ad'"
        :data="filter"
        :is-parent="isParent"
        :sidebar="sidebar"
        @change-value="updateAttrs($event, filter.id)"
        @update-availability="save"
      />
      <div
        class="overflow-hidden transition-all"
        :class="{
          'max-h-0': !isExpanded,
          'max-h-[9999px]': isExpanded,
          'lg:hidden': sidebar
        }"
      >
        <attr-item-small
          v-for="filter in additionalAttrs"
          :key="filter.id"
          :attr-type="isParent ? 'nanny' : 'ad'"
          :data="filter"
          :is-parent="isParent"
          :sidebar="sidebar"
          @change-value="updateAttrs($event, filter.id)"
          @update-availability="save"
        />
      </div>
    </div>
    <div
      v-if="additionalAttrs?.length > 0"
      class="block w-full cursor-pointer border-x border-t bg-white py-4 text-center text-sm font-bold text-secondary-500 lg:w-auto"
      :class="{
        'pb-20 lg:pb-2': !sidenav.isMobile,
        'px-4 pb-[184px] lg:pb-2': sidenav.isMobile
        // 'px-4 pb-24 lg:pb-2': sidenav.isMobile && !sidenav.isSafari,
        // 'px-4 pb-40 lg:pb-2': sidenav.isMobile && sidenav.isSafari
      }"
      @click="isExpanded = !isExpanded"
    >
      {{
        !isExpanded
          ? $t('sidebar.filters.showMore')
          : $t('sidebar.filters.showLess')
      }}
    </div>
    <div
      class="fixed bottom-0 block w-full border-t border-t-gray-200 bg-white px-6 pt-4 pb-4 lg:static lg:w-auto lg:rounded-b-lg lg:border"
      :class="[sidenav.isMobile ? 'mb-20 pb-8' : '']"
    >
      <btn
        :text="$t('sidebar.filters.btn')"
        color="secondary"
        size="lg"
        :disabled="disabledBtn"
        @click="save"
      />
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, nextTick, onMounted, ref } from 'vue'
import { useAuthStore } from '@stores/auth'
import { useFiltersStore } from '@stores/filters'
import { useRoute } from 'vue-router'
import { useMediaQuery } from '@vueuse/core'
import AttrItemSmall from '@portal/AttrItemSmall'
import AttrSkeleton from '@portal/AttrSkeleton'
import Btn from '@atoms/Btn'
import { useSidenavStore } from '@stores/sidenav'
// import apiAds from '@db/Announcments'
import apiFilters from '@db/Filters'
import apiSearch from '@db/Search'
import {
  DataObject,
  SidebarAttr,
  // SelectedItems,
  DataToSend
} from '@db/Filters/interfaces'

export default defineComponent({
  components: {
    AttrItemSmall,
    Btn,
    AttrSkeleton
  },
  props: {
    sidebar: Boolean
  },
  emits: ['close'],
  setup(props, { emit }) {
    const filterState = useFiltersStore()
    const sidenav = useSidenavStore()
    const disabledBtn = ref(true)
    const useAuth = useAuthStore()
    const isParent = useAuth.isParent
    const route = useRoute()
    const attrsRes = ref([] as SidebarAttr[])
    const isLoading = ref(true)
    const state = ref([] as DataObject[])
    const valuesState = ref([] as DataToSend[])
    const isExpanded = ref(false)
    const isMobile = navigator.userAgentData?.mobile

    const isLargeScreen = useMediaQuery('(min-width: 1024px)')

    const shouldRender = computed(() => {
      return props.sidebar ? !isLargeScreen.value : isLargeScreen.value
    })

    const mainAttrs = ref()
    const additionalAttrs = ref()
    const activeVals = ref([] as string[])

    const getAllData = async () => {
      let attrs
      if (isParent) {
        attrs = await apiFilters.readState()
      } else {
        attrs = await apiSearch.readAdsFilter()
      }
      attrsRes.value = attrs.data.filters
      activeVals.value = attrs.data.values
      if (isParent) {
        mainAttrs.value = attrs.data.filters.filter(
          (a: any) => a.attribute_basic_nanny === 1
        )
        additionalAttrs.value = attrs.data.filters.filter(
          (a: any) => a.attribute_basic_nanny !== 1
        )
      } else {
        mainAttrs.value = attrs.data.filters.filter(
          (a: any) => a.attribute_basic_ad === 1
        )
        additionalAttrs.value = attrs.data.filters.filter(
          (a: any) => a.attribute_basic_ad !== 1
        )
      }
    }

    const updateAttrs = (data: string[], id: number) => {
      const item = attrsRes.value.find((el: SidebarAttr) => el.id === id)
      if (item) {
        item.values.forEach((element) => {
          element.attribute_checked = data.includes(element.attribute_value)
        })
      }
      const newVals = [] as Array<string>
      attrsRes.value.forEach((item) => {
        item.values.forEach((val) => {
          if (val.attribute_checked) {
            newVals.push(val.attribute_value)
          }
        })
      })
      activeVals.value = newVals
      disabledBtn.value = false
    }

    onMounted(async () => {
      isLoading.value = true
      await getAllData()
      isLoading.value = false
      await nextTick()
      disabledBtn.value = true
    })

    const save = async () => {
      if (isParent) {
        await apiFilters.updateFilters(activeVals.value)
      } else {
        await apiSearch.updateAdsFilter(activeVals.value)
      }
      filterState.changed++
      disabledBtn.value = true
      await getAllData()
      emit('close')
    }

    const showFilters = computed(
      () => !isParent || (isParent && route.name !== 'jobsAd')
    )

    return {
      disabledBtn,
      isParent,
      save,
      isLoading,
      updateAttrs,
      state,
      valuesState,
      showFilters,
      mainAttrs,
      additionalAttrs,
      isExpanded,
      shouldRender,
      isMobile,
      sidenav
    }
  }
})
</script>
