import { createRouter, createWebHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import { tryAutoLogin, checkAuthenticated } from '../middleware/Auth'
import AdsRoutes from './AdsRoutes'
import AccountRoutes from './AccountRoutes'
import AttributesRoutes from './AttributesRoutes'
import FiltersRoutes from './FiltersRoutes'
import DealsRoutes from './DealsRoutes'
import JobsRoutes from './JobsRoutes'
import InboxRoutes from './InboxRoutes'
import OffersRoutes from './OffersRoutes'
import LikesRoutes from './LikesRoutes'
import WorktimeRoutes from './WorktimeRoutes'
import KidsRoutes from './KidsRoutes'
import MainRoutes from './MainRoutes'
import NanniesRoutes from './NanniesRoutes'
import BlogRoutes from './BlogRoutes'
import ProfilRoutes from './ProfilRoutes'
import StartRoutes from './StartRoutes'
import OnboardingRoutes from './OnboardingRoutes'
import NotificationsRoutes from './NotificationsRoutes'
import SearchRoutes from './SearchRoutes'
import AuthRoutes from './AuthRoutes'
import LogoutRoutes from './LogoutRoutes'
import OauthRoutes from './OauthRoutes'
import LayoutDefault from '../layouts/LayoutDefault.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: LayoutDefault,
    children: [
      AdsRoutes,
      AccountRoutes,
      AttributesRoutes,
      DealsRoutes,
      InboxRoutes,
      OffersRoutes,
      LikesRoutes,
      WorktimeRoutes,
      FiltersRoutes,
      JobsRoutes,
      KidsRoutes,
      MainRoutes,
      NanniesRoutes,
      BlogRoutes,
      ProfilRoutes,
      StartRoutes,
      OnboardingRoutes,
      NotificationsRoutes,
      SearchRoutes
    ]
  },
  OauthRoutes,
  LogoutRoutes,
  AuthRoutes
]

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
  scrollBehavior() {
    return { top: 0 }
  }
})

router.beforeEach(tryAutoLogin)
router.beforeEach(checkAuthenticated)

export default router
