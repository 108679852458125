<template>
  <div class="mb-2 md:mb-6">
    <div class="flex h-max flex-col border bg-white md:rounded-lg">
      <div class="flex px-4 pt-5 pb-2 md:px-6 md:pb-6 md:pt-6">
        <div class="mr-4 flex items-start md:mr-6">
          <icon
            name="slackHash"
            size="custom"
            class="h-12 w-12 text-primary-500 md:h-20 md:w-20"
          />
        </div>
        <div>
          <h5
            class="mb-4 mt-1 text-sm font-bold uppercase text-secondary-700 md:whitespace-nowrap md:text-base"
          >
            {{
              isParent
                ? $t('profile.hashtag.titleParent')
                : $t('profile.hashtag.titleNanny')
            }}
          </h5>
          <div>
            <badge
              v-for="(tag, idx) in tags"
              :key="idx"
              color="secondary"
              class="mr-2 mb-2"
              size="lg"
              rounded
            >
              {{ tag }}
            </badge>
            <router-link
              v-if="otherTagsCount > 0"
              class="font-bold text-secondary"
              to="account/tags"
            >
              +{{ otherTagsCount }} {{ $t('profile.hashtag.more') }}
            </router-link>
          </div>
        </div>
      </div>
      <router-link
        class="relative flex items-center justify-between gap-6 border-t py-3 px-4 text-left text-base font-bold text-danger-600 hover:bg-gray-100 md:px-6"
        to="/account/tags"
      >
        {{
          isParent
            ? $t('profile.hashtag.descParent')
            : $t('profile.hashtag.descNanny')
        }}
        <icon name="angleRightSolid" size="4" class="inline text-warning-600" />
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
// import Btn from '@atoms/Btn'
import Badge from '@atoms/Badge'
import Icon from '@atoms/Icon'

export default defineComponent({
  name: 'HashTags',
  components: {
    // Btn,
    Badge,
    Icon
  },
  props: {
    tags: {
      type: Array,
      required: true
    },
    otherTagsCount: {
      type: Number,
      required: true
    },
    isParent: {
      type: Boolean,
      required: true
    }
  }
})
</script>
