import LayoutDefault from '@layouts/LayoutBase.vue'

const routes = {
  path: 'search',
  component: LayoutDefault,
  meta: {
    forLogged: true,
    forNanny: false,
    forParent: true
  },
  children: [
    {
      path: '',
      name: 'searchstart',
      component: () => import('../views/search/SearchIndex.vue')
    },
    {
      path: 'fornow',
      name: 'searchfornow',
      meta: {
        store: 'fornow'
      },
      component: () => import('../views/search/SearchTime.vue')
    },
    {
      path: 'evening',
      name: 'searchevening',
      meta: {
        store: 'evening'
      },
      component: () => import('../views/search/SearchTime.vue')
    },
    {
      path: 'hours',
      name: 'searchhours',
      meta: {
        store: 'hours'
      },
      component: () => import('../views/search/SearchTime.vue')
    },
    {
      path: 'permanently',
      name: 'searchpermanently',
      meta: {
        store: 'permanently'
      },
      component: () => import('../views/search/SearchKids.vue')
    },
    {
      path: 'fornow/kids',
      name: 'searchfornowkids',
      meta: {
        store: 'fornow'
      },
      component: () => import('../views/search/SearchKids.vue')
    },
    {
      path: 'evening/kids',
      name: 'searcheveningkids',
      meta: {
        store: 'evening'
      },
      component: () => import('../views/search/SearchKids.vue')
    },
    {
      path: 'hours/kids',
      name: 'searchhourskids',
      meta: {
        store: 'hours'
      },
      component: () => import('../views/search/SearchKids.vue')
    },
    {
      path: 'permanently/care',
      name: 'searchpermanentlycare',
      meta: {
        store: 'permanently'
      },
      component: () => import('../views/search/SearchCare.vue')
    },
    {
      path: 'fornow/care',
      name: 'searchfornowcare',
      meta: {
        store: 'fornow'
      },
      component: () => import('../views/search/SearchCare.vue')
    },
    {
      path: 'evening/care',
      name: 'searcheveningcare',
      meta: {
        store: 'evening'
      },
      component: () => import('../views/search/SearchCare.vue')
    },
    {
      path: 'hours/care',
      name: 'searchhourscare',
      meta: {
        store: 'hours'
      },
      component: () => import('../views/search/SearchCare.vue')
    }
  ]
}

export default routes
