<template>
  <div
    v-if="!isHidden"
    class="w-full overflow-y-hidden border-l border-r border-t border-gray-200 bg-white pb-4 transition-all hover:bg-success-100"
    :class="{
      'max-h-screen': isOpen,
      'max-h-12': !isOpen,
      'hidden lg:block': !sidebar
    }"
  >
    <div
      class="flex cursor-pointer justify-between py-4 px-4 transition-all lg:px-8"
      @click="isOpen = !isOpen"
    >
      <span
        class="text-xs font-bold uppercase text-warning-500"
        :class="isOpen ? '' : 'truncate'"
      >
        {{
          attrType === 'nanny'
            ? data.attribute_name_ad
            : data.attribute_name_nanny
        }}
      </span>
      <div>
        <icon
          name="angleRightSolid"
          class="transition-all"
          size="3"
          :class="isOpen ? 'rotate-90' : '-rotate-90'"
        />
      </div>
    </div>
    <form-fieldset class="mb-0 px-4 lg:px-8">
      <form-field :name="data.id.toString()">
        <!-- Text -->
        <form-text
          v-if="data.attribute_field_type === 'text'"
          v-model="payload[0]"
        />
        <!-- Textarea -->
        <form-textarea
          v-else-if="data.attribute_field_type === 'textarea'"
          v-model="payload[0]"
          :rows="3"
        />
        <!-- Select -->
        <form-select
          v-else-if="data.attribute_field_type === 'select'"
          v-model="payload[0]"
          :items="data.values"
          item-text="attribute_option"
          item-value="attribute_value"
        />
        <!-- Radio -->
        <div v-else-if="data.attribute_field_type === 'radio'" class="grid">
          <form-radio
            v-for="item in data.values"
            :key="item.attribute_value"
            v-model="payload[0]"
            :name="data.id"
            :option="item.attribute_value"
            :label="item.attribute_option"
            class="mr-4 py-1"
          />
        </div>
        <!-- Checkbox -->
        <div v-else-if="data.attribute_field_type === 'checkbox'">
          <form-radio
            v-for="item in data.values"
            :key="item.attribute_value"
            v-model="payload[0]"
            :option="item.attribute_value"
            :label="item.attribute_option"
            class="p-2"
          />
        </div>
        <!-- Currency -->
        <form-currency
          v-else-if="data.attribute_field_type === 'price'"
          v-model="payload[0]"
          :options="{ currency: 'PLN' }"
        />
        <!-- Multiselect -->
        <div
          v-else-if="
            data.attribute_field_type === 'multiselect' && !isNannyForNow
          "
        >
          <div
            v-for="item in data.values"
            :key="item.id"
            class="flex items-center gap-2 py-1"
          >
            <input
              :id="item.attribute_value"
              v-model="payload"
              :name="item.attribute_value"
              type="checkbox"
              :value="item.attribute_value"
              class="flex h-4 w-4 items-center rounded border-secondary-300 text-secondary-400 focus:ring-secondary-400"
            />
            <form-label
              class="cursor-pointer"
              :text="item.attribute_option"
              :for="item.attribute_value"
            />
          </div>
        </div>
        <div
          v-else-if="
            data.attribute_field_type === 'multiselect' && isNannyForNow
          "
        >
          <div v-for="item in data.values" :key="item.id" class="gap-2 py-1">
            <div class="flex space-x-2">
              <input
                :id="item.id"
                v-model="payload"
                :name="item.id"
                type="checkbox"
                :value="item.attribute_value"
                class="flex h-4 w-4 items-center rounded border-secondary-300 text-secondary-400 focus:ring-secondary-400"
                @input="openAvailabilityModal"
              />
              <form-label
                class="cursor-pointer"
                :text="item.attribute_option"
                :for="item.id"
                @click="clickNannyForNowLabel(item.attribute_value)"
              />
            </div>
            <!-- <span
              v-if="payload.length > 0"
              class="cursor-pointer text-xs text-danger-500"
              @click="useOverlay.show('ModalAvailability')"
            >
              Edytuj godziny dostępności
            </span> -->
          </div>
        </div>
      </form-field>
    </form-fieldset>
  </div>
  <!-- <modal-availability
    v-else
    :data="data"
    :payload="payload"
    @updatePayload="payload = $event"
    @update-availability="$emit('updateAvailability')"
  /> -->
</template>

<script lang="ts">
import { defineComponent, ref, watch, onMounted } from 'vue'
import { useOverlayStore } from '@stores/overlay'
import FormText from '@atoms/Form/FormText'
import FormTextarea from '@atoms/Form/FormTextarea'
import FormSelect from '@atoms/Form/FormSelect'
import FormRadio from '@atoms/Form/FormRadio'
import FormField from '@atoms/Form/FormField'
import FormLabel from '@atoms/Form/FormLabel'
import FormFieldset from '@atoms/Form/FormFieldset'
import FormCurrency from '@atoms/Form/FormCurrency'
// import ModalAvailability from '@portal/ModalAvailability'
import Icon from '@atoms/Icon'

export default defineComponent({
  name: 'AttrItemSmall',
  components: {
    FormCurrency,
    FormText,
    FormTextarea,
    FormLabel,
    FormField,
    FormFieldset,
    FormRadio,
    FormSelect,
    // ModalAvailability,
    Icon
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    attrType: {
      type: String,
      required: true,
      validator: (v: string) => ['nanny', 'baby', 'ad'].includes(v)
    },
    isParent: {
      type: Boolean,
      default: false
    },
    sidebar: Boolean
  },
  emits: ['changeValue', 'updateAvailability'],
  setup(props, context) {
    const useOverlay = useOverlayStore()
    const isHidden = props.data.attribute_tag === 'DostepnoscNaJuż'
    const isNannyForNow = props.data.attribute_tag === 'NianiaNaJuż'

    const payload = ref([] as string[])

    watch(
      () => [...payload.value],
      () => {
        context.emit('changeValue', payload.value)
      }
    )

    const setInitialPayload = () => {
      // console.log(props.data.values)
      if (props.data.values) {
        if (
          props.data.attribute_field_type === 'multiselect' ||
          props.data.attribute_field_type === 'checkbox' ||
          props.data.attribute_field_type === 'select' ||
          props.data.attribute_field_type === 'radio'
        ) {
          for (let i = 0; i < props.data.values.length; i++) {
            if (props.data.values[i].attribute_checked) {
              payload.value.push(props.data.values[i].attribute_value)
            }
          }
          if (props.data.attribute_field_type === 'select') {
            payload.value.shift()
          }
        } else {
          payload.value[0] = props.data.values
        }
      } else payload.value = []
    }
    setInitialPayload()

    const isOpen = ref(false)

    onMounted(() => {
      isOpen.value = payload.value.length > 0
    })
    const changePayload = (val: string) => {
      const index = payload.value.findIndex((el) => el === val)
      if (index === -1) {
        payload.value.push(val)
      } else {
        payload.value.splice(index, 1)
      }
    }

    const openAvailabilityModal = (v: Event) => {
      if ((v.target as HTMLInputElement).checked) {
        useOverlay.show('ModalAvailability')
      }
    }

    const clickNannyForNowLabel = (v: string) => {
      changePayload(v)
      useOverlay.show('ModalAvailability')
    }

    return {
      payload,
      isOpen,
      isHidden,
      isNannyForNow,
      changePayload,
      openAvailabilityModal,
      useOverlay,
      clickNannyForNowLabel
    }
  }
})
</script>
