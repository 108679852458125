<template>
  <div class="mx-auto py-6 px-0 md:px-6 lg:max-w-7xl">
    <div
      class="grid gap-x-8"
      :class="isMainFilterPage ? 'grid-cols-2 lg:grid-cols-3' : 'grid-cols-2'"
    >
      <portal-filters-menu />
      <div :class="showFilters ? 'col-span-2' : 'col-span-3'">
        <div v-if="isMainFilterPage">
          <div>
            <hashtags
              :is-parent="isParent"
              :tags="tags"
              :other-tags-count="otherTagsCount"
            />
          </div>
          <div
            class="mb-4 mt-4 flex flex-wrap gap-2 px-4 md:mb-2 md:mt-2 md:px-0"
          >
            <select
              class="text-md w-auto rounded-md border-gray-300 py-2 px-3 pl-3 pr-10 text-base focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
              @change="updateDistance"
            >
              <option
                v-for="distance in [5, 10, 15, 30, 1000]"
                :key="distance"
                :value="distance"
                :selected="true"
              >
                Odległość: {{ distance }} km
              </option>
            </select>
            <!-- <div>
              {{
                isParent
                  ? $t('sidebar.filters.showNannies')
                  : $t('sidebar.filters.showAds')
              }}
              <input
                type="number"
                onkeyup="if (this.value < 0) { this.value = this.value * -1 }"
                class="mx-1 my-2 w-12 rounded-md border py-0 px-2 text-center [appearance:textfield] focus:border-black focus:outline-none focus:ring-0 [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                @input="updateDistance"
              />
              {{ $t('sidebar.filters.km') }}
            </div> -->
            <btn
              text="Pokaż filtry"
              class="lg:hidden"
              icon="slidersHSolid"
              outline
              @click="displaySlideOver()"
            />
            <btn
              color="secondary"
              :text="$t('sidebar.filters.weight')"
              :icon="weightIcon"
              outline
              @click="changeSort('weight')"
            />
            <btn
              color="secondary"
              :text="$t('sidebar.filters.distance')"
              :icon="distanceIcon"
              outline
              @click="changeSort('distance')"
            />
            <btn
              color="secondary"
              :text="
                isParent
                  ? $t('sidebar.filters.activity')
                  : $t('sidebar.filters.added')
              "
              :icon="activityIcon"
              outline
              @click="changeSort('created')"
            />
          </div>
        </div>
        <router-view />
      </div>
    </div>
  </div>
  <!-- <portal-slide-over class="lg:hidden" /> -->
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { useAuthStore } from '@stores/auth'
import { useFiltersStore } from '@stores/filters'
// import { useOverlayStore } from '@stores/overlay'
import { useSidenavStore } from '@stores/sidenav'
import { useRoute } from 'vue-router'
import PortalFiltersMenu from '@portal/PortalFiltersMenu'
import Hashtags from '@portal/Hashtags'
import Btn from '@atoms/Btn'
// import PortalSlideOver from '@portal/PortalSlideOver'
import apiHashtags from '@db/Hashtags'

export default defineComponent({
  components: {
    PortalFiltersMenu,
    Btn,
    Hashtags
    // PortalSlideOver
  },
  setup() {
    const filterState = useFiltersStore()
    const sidenav = useSidenavStore()
    const useAuth = useAuthStore()
    const isParent = useAuth.isParent
    const route = useRoute()
    const tags = ref([])
    const otherTagsCount = ref(0)

    watch(
      () => route.name,
      async () => {
        if (isMainFilterPage.value) {
          const d = await apiHashtags.readAll()
          if (d.data[0]?.values) {
            const hashtagsData = d.data[0].values
            otherTagsCount.value = hashtagsData.split(',').length - 3
            tags.value = hashtagsData.split(',').slice(0, 3)
          }
        }
      }
    )

    onMounted(async () => {
      const d = await apiHashtags.readAll()
      if (d.data[0]?.values) {
        const hashtagsData = d.data[0].values
        otherTagsCount.value = hashtagsData.split(',').length - 3
        tags.value = hashtagsData.split(',').slice(0, 3)
      }
    })

    const updateDistance = (ev: Event) => {
      const value = (ev.target as HTMLInputElement).value.trim()
      filterState.distance = value.length === 0 ? 30 : +value
    }

    const showFilters = computed(
      () => !isParent || (isParent && route.name !== 'jobsAd')
    )
    const isMainFilterPage = computed(
      () => route.name === 'jobs' || route.name === 'nannies'
    )

    const distanceIcon = computed(() =>
      filterState.sort === 'distance'
        ? filterState.sort_type === 'asc'
          ? 'angleDownSolid'
          : 'angleUpSolid'
        : 'angleRightSolid'
    )
    const activityIcon = computed(() =>
      filterState.sort === 'created'
        ? filterState.sort_type === 'asc'
          ? 'angleDownSolid'
          : 'angleUpSolid'
        : 'angleRightSolid'
    )
    const weightIcon = computed(() =>
      filterState.sort === 'weight'
        ? filterState.sort_type === 'desc'
          ? 'angleDownSolid'
          : 'angleUpSolid'
        : 'angleRightSolid'
    )
    const changeSort = async (type: string) => {
      if (type === filterState.sort) {
        filterState.sort_type === 'asc'
          ? (filterState.sort_type = 'desc')
          : (filterState.sort_type = 'asc')
      } else {
        filterState.sort = type
        filterState.sort_type = 'asc'
      }
      filterState.page = 1
      filterState.change()
    }

    const displaySlideOver = () => {
      sidenav.change()
      sidenav.setHorizontal()
    }

    return {
      isParent,
      showFilters,
      isMainFilterPage,
      updateDistance,
      tags,
      otherTagsCount,
      distanceIcon,
      activityIcon,
      weightIcon,
      changeSort,
      displaySlideOver
    }
  }
})
</script>
