<template>
  <div class="mx-auto max-w-7xl p-6">
    <portal-breadcrumb />
    <div v-if="step === 1" class="mx-auto mt-3 md:max-w-md">
      <three-line-heading
        class="mb-8"
        :title="$t('onboarding.step1.title')"
        :description="$t('onboarding.step1.description')"
      />
      <form-fieldset class="mb-8">
        <form-group>
          <form-field name="state.user_account" class="!gap-1">
            <form-label :text="$t('onboarding.step1.account')" />
            <form-select
              v-model="state.user_account"
              :items="options.account"
              item-text="text"
              item-value="value"
            />
          </form-field>
          <form-field name="state.user_gender" class="!gap-1">
            <form-label :text="$t('onboarding.step1.gender')" />
            <form-select
              v-model="state.user_gender"
              :items="options.gender"
              item-text="text"
              item-value="value"
            />
          </form-field>
          <template v-if="isGoogle">
            <form-field name="state.user_firstname" :v="vName.user_firstname">
              <form-label :text="$t('onboarding.step1.firstName')" />
              <form-text v-model.trim="state.user_firstname" />
              <form-valid
                :text="$t('onboarding.validation.required')"
                check="required"
              />
              <form-valid
                :text="$t('onboarding.validation.minlength', { length: 2 })"
                check="minLength"
              />
              <form-valid
                :text="$t('onboarding.validation.maxlength', { length: 32 })"
                check="maxLength"
              />
            </form-field>
            <form-field name="state.user_lastname" :v="vName.user_lastname">
              <form-label :text="$t('onboarding.step1.lastName')" />
              <form-text v-model.trim="state.user_lastname" />
              <form-valid
                :text="$t('onboarding.validation.required')"
                check="required"
              />
              <form-valid
                :text="$t('onboarding.validation.minlength', { length: 2 })"
                check="minLength"
              />
              <form-valid
                :text="$t('onboarding.validation.maxlength', { length: 32 })"
                check="maxLength"
              />
            </form-field>
          </template>
        </form-group>
      </form-fieldset>
      <btn
        :text="$t('onboarding.step1.save')"
        color="secondary"
        @click="nextStep"
      />
    </div>
    <div v-if="step === 2" class="mx-auto mt-3 md:max-w-md">
      <three-line-heading
        class="mb-8"
        :title="
          state.user_account === 'parent'
            ? $t('onboarding.step2.parent.title')
            : $t('onboarding.step2.nanny.title')
        "
        :description="
          state.user_account === 'parent'
            ? $t('onboarding.step2.parent.description')
            : $t('onboarding.step2.nanny.description')
        "
      />
      <form-fieldset class="mb-8 space-y-4">
        <form-field name="state.address_street" :v="v.address_street">
          <form-label :text="$t('onboarding.step2.street')" />
          <form-text v-model.trim="state.address_street" />
          <form-valid
            :text="$t('onboarding.validation.required')"
            check="required"
          />
          <form-valid
            :text="$t('onboarding.validation.minlength', { length: 2 })"
            check="minLength"
          />
          <form-valid
            :text="$t('onboarding.validation.maxlength', { length: 32 })"
            check="maxLength"
          />
        </form-field>
        <form-group template="horizontal" class="mb-4 grid-cols-2 items-start">
          <form-field
            name="state.address_house_number"
            :v="v.address_house_number"
          >
            <form-label :text="$t('onboarding.step2.house')" />
            <form-text v-model.trim="state.address_house_number" />
            <form-valid
              :text="$t('onboarding.validation.required')"
              check="required"
            />
          </form-field>
          <form-field
            name="state.address_local_number"
            :v="v.address_local_number"
          >
            <form-label :text="$t('onboarding.step2.local')" />
            <form-text v-model.trim="state.address_local_number" />
          </form-field>
        </form-group>
        <form-group template="horizontal" class="mb-4 grid-cols-2 items-start">
          <form-field name="state.address_city" :v="v.address_city">
            <form-label :text="$t('onboarding.step2.city')" />
            <form-text v-model.trim="state.address_city" />
            <form-valid
              :text="$t('onboarding.validation.required')"
              check="required"
            />
            <form-valid
              :text="$t('onboarding.validation.minlength', { length: 2 })"
              check="minLength"
            />
            <form-valid
              :text="$t('onboarding.validation.maxlength', { length: 32 })"
              check="maxLength"
            />
          </form-field>
          <form-field name="state.address_postcode" :v="v.address_postcode">
            <form-label :text="$t('onboarding.step2.zipCode')" />
            <form-text v-model.trim="state.address_postcode" />
            <form-valid
              :text="$t('onboarding.validation.required')"
              check="required"
            />
          </form-field>
        </form-group>
        <form-field name="state.user_phone" :v="v.user_phone">
          <form-label :text="$t('onboarding.step2.telephone')" />
          <form-text v-model.trim="state.user_phone" />
          <form-valid
            :text="$t('onboarding.validation.required')"
            check="required"
          />
        </form-field>
        <form-field
          v-if="state.user_account === 'nanny'"
          name="state.user_birth"
          :v="v.user_birth"
        >
          <form-label :text="$t('onboarding.step2.birth')" />
          <form-text
            v-model="state.user_birth"
            v-maska="'####-##-##'"
            placeholder="YYYY-MM-DD"
          >
            <template #right>
              <dropdown id="target" :tabindex="0" :display="show" class="flex">
                <div
                  class="flex h-full items-center justify-center rounded-r-md bg-[#e9ecef] px-3"
                  @click="toggle"
                >
                  <icon name="calendarAltSolid" size="6" />
                </div>
                <template #content>
                  <form-date class="mx-1.5" @date="pick($event)" />
                </template>
              </dropdown>
            </template>
          </form-text>
          <form-valid
            :text="$t('onboarding.validation.required')"
            check="requiredIfNanny"
          />
        </form-field>
      </form-fieldset>
      <btn
        :text="$t('onboarding.step2.save')"
        color="secondary"
        @click="send"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { onClickOutside } from '@vueuse/core'
import { useToastStore } from '@stores/toast'
import { useAuthStore } from '@stores/auth'

import useVuelidate from '@vuelidate/core'
import {
  required,
  minLength,
  maxLength,
  requiredIf
} from '@vuelidate/validators'

import ThreeLineHeading from '@portal/ThreeLineHeading'
import PortalBreadcrumb from '@portal/PortalBreadcrumb'
import FormLabel from '@atoms/Form/FormLabel'
// import { useRouter } from 'vue-router'
import Icon from '@atoms/Icon'
import Btn from '@atoms/Btn'
import Dropdown from '@molecules/Dropdown'
import FormField from '@atoms/Form/FormField'
import FormFieldset from '@atoms/Form/FormFieldset'
import FormGroup from '@atoms/Form/FormGroup'
import FormSelect from '@atoms/Form/FormSelect'
import FormText from '@atoms/Form/FormText'
import FormValid from '@atoms/Form/FormValid'
import FormDate from '@organisms/Form/FormDate'

import apiAccount from '@db/Account'
import apiAuth from '@db/Auth'

export default defineComponent({
  name: 'OnboardingView',
  components: {
    ThreeLineHeading,
    PortalBreadcrumb,
    Btn,
    FormField,
    FormFieldset,
    FormGroup,
    FormSelect,
    FormLabel,
    FormText,
    FormValid,
    FormDate,
    Dropdown,
    Icon
  },
  setup() {
    const useToast = useToastStore()
    const useAuth = useAuthStore()
    const user = useAuth.getUser
    const isGoogle = ref(false)
    const { t } = useI18n()
    const state = reactive({
      user_account: 'parent',
      user_firstname: user.user_firstname,
      user_lastname: user.user_lastname,
      user_gender: 'female',
      address_street: '',
      address_postcode: '',
      address_city: '',
      address_house_number: '',
      address_local_number: '',
      user_phone: '',
      user_birth: '',
      address_district: '',
      address_lat: '',
      address_lng: '',
      address_state: '',
      user_is_visible: 1
    })
    const options = {
      account: [
        { value: 'parent', text: t('onboarding.step1.parent') },
        { value: 'nanny', text: t('onboarding.step1.nanny') }
      ],
      gender: [
        { value: 'female', text: t('onboarding.step1.woman') },
        { value: 'male', text: t('onboarding.step1.man') }
      ]
    }
    const step = ref(1)

    const rules = {
      address_street: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(32)
      },
      address_house_number: {
        required
      },
      address_postcode: {
        required
      },
      address_city: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(32)
      },
      user_phone: {
        required
      },
      user_birth: {
        requiredIfNanny: requiredIf(() => state.user_account !== 'parent')
      }
    }

    const nameRules = {
      user_firstname: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(32)
      },
      user_lastname: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(32)
      }
    }

    const vName = useVuelidate(nameRules, state)
    const v = useVuelidate(rules, state)

    const target = ref()
    const pickDate = ref()
    const show = ref(false)
    const toggle = () => {
      show.value = !show.value
    }
    const hide = () => {
      show.value = false
    }
    onMounted(async () => {
      target.value = document.getElementById('target')
      onClickOutside(target, hide)
      const d = await apiAuth.getProvider()
      if (d.data === 'google') {
        isGoogle.value = true
      }
    })
    const pick = (v: string) => {
      pickDate.value = v
      state.user_birth = v
      show.value = false
    }
    const updateCoords = async () => {
      const placeUrl = () => {
        const str = state.address_street
        const houseNr = state.address_house_number
        const zip = state.address_postcode
        const city = state.address_city
        return `https://nominatim.openstreetmap.org/?addressdetails=1&q=${str}+${houseNr}+${zip}+${city}&format=json&limit=1&accept-language=pl`
      }
      const dd = await fetch(placeUrl())
      const d = await dd.json()
      if (d[0] !== undefined) {
        state.address_lat = d[0].lat
        state.address_lng = d[0].lon
        state.address_district =
          d[0].address.city_district || d[0].address.suburb || 'Brak dzielnicy'
        state.address_state = d[0].address.state
      } else {
        useToast.add({
          type: 'warning',
          title: t('account.address.update.toasts.fail.title'),
          msg: t('account.address.update.toasts.fail.msg'),
          icon: 'exclamationCircleSolid',
          duration: 5000
        })
      }
    }

    const nextStep = () => {
      vName.value.$touch()
      if (vName.value.$errors.length === 0) {
        step.value++
      }
    }

    const send = async () => {
      v.value.$touch()
      if (v.value.$errors.length === 0) {
        await updateCoords()
        if (state.address_lat) {
          const payload =
            state.user_account !== 'parent'
              ? state
              : Object.assign(state, { user_birth: '1900-01-01' })
          await apiAccount.patchUser(payload).then(() => {
            window.location.reload()
          })
        } else {
          console.log('address error')
        }
      }
    }

    return {
      options,
      state,
      send,
      step,
      v,

      show,
      toggle,
      hide,
      onClickOutside,
      pick,
      target,
      user,
      isGoogle,
      vName,
      nextStep
    }
  }
})
</script>
