<template>
  <div
    class="sidenav fixed w-full"
    :class="{
      'sidenav-open':
        sidenav.isMobile && sidenav.isOpen && sidenav.horizontal === 'left',
      'slideover-open':
        sidenav.isMobile && sidenav.isOpen && sidenav.horizontal === 'right'
    }"
  >
    <div class="flex">
      <portal-sidebar class="flex-none" />
      <div class="relative flex-1 overflow-hidden">
        <router-view />
        <transition v-bind="opacity.opacityTransitionClasses">
          <div
            v-if="sidenav.isOpen && sidenav.isMobile"
            class="absolute inset-0 z-20"
            @click="sidenav.close"
          >
            <div class="absolute inset-0 bg-gray-300 opacity-80" />
          </div>
        </transition>
      </div>
      <portal-mobile-filters class="flex-none" />
    </div>
    <toasts-group />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onDeactivated,
  onBeforeMount,
  watch,
  computed
} from 'vue'
import { useRoute } from 'vue-router'
import { useSidenavStore } from '@stores/sidenav'
import PortalSidebar from '@portal/PortalSidebar'
import PortalMobileFilters from '@portal/PortalMobileFilters'
import ToastsGroup from '@molecules/ToastsGroup'
import useOpacityQuark from '@quarks/OpacityQuark'

declare global {
  interface Window {
    MSStream: string
  }
}

export default defineComponent({
  name: 'LayoutDefault',
  components: {
    PortalSidebar,
    PortalMobileFilters,
    ToastsGroup
  },
  setup() {
    const sidenav = useSidenavStore()
    const opacity = useOpacityQuark()
    const handleResize = () => {
      window.innerWidth > 1024 ? sidenav.setDesktop() : sidenav.setMobile()
    }

    onBeforeMount(() => {
      window.addEventListener('resize', handleResize)
      // eslint-disable-next-line no-useless-escape
      const isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/)
      if (isSafari) sidenav.setSafari()
      if (window !== undefined) {
        const win: Window & typeof globalThis = window
        const iOS =
          /iPad|iPhone|iPod/.test(navigator.userAgent) && !win.MSStream
        if (iOS) sidenav.setIos()
      }

      handleResize()
    })

    onDeactivated(() => {
      window.removeEventListener('resize', handleResize)
    })

    const route = useRoute()
    watch(route, () => {
      if (sidenav.isMobile) sidenav.close()
    })
    const isOpen = computed(() => sidenav.isOpen)
    return {
      isOpen,
      opacity,
      sidenav
    }
  }
})
</script>

<style lang="postcss" scoped>
.sidenav {
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate3d(-288px, 0, 0);
  width: calc(100vw + 608px);
}
.sidenav-open {
  transform: translate3d(0px, 0, 0);
}
.slideover-open {
  transform: translate3d(-608px, 0, 0);
}
</style>
