<template>
  <div class="mx-auto max-w-7xl md:p-6">
    <div class="grid-cols-12 gap-x-8 lg:grid">
      <div class="hidden lg:col-span-3 lg:flex lg:flex-col lg:gap-4">
        <side-menu :is-parent="isParent" />
      </div>
      <div class="col-span-12 grid grid-cols-2 gap-x-8 lg:col-span-9">
        <div class="col-span-2 px-4 pt-6 md:px-0 md:pt-0">
          <three-line-heading
            class="mb-8"
            :title="$t('profile.main.title')"
            :description="$t('profile.main.description')"
          >
            <template #infoText>
              {{ $t('profile.main.infoText') }}
            </template>
          </three-line-heading>
        </div>
        <!-- <profile-introduce /> -->
        <!-- <div class="col-span-2">
          <profile-about>
            <form-field name="review_comment" :v="v.description">
              <form-textarea
                v-model="description"
                class="bg-white text-secondary-800"
                size="sm"
                @change="changeDescription"
              />
              <form-valid
                check="maxLength"
                :text="$t('profile.description.warning')"
              />
            </form-field>
            <form-valid
              :text="$t('profile.description.instruction')"
              class="!text-gray-600"
            />
          </profile-about>
        </div>
        <div class="col-span-2">
          <hashtags
            :isParent="isParent"
            :tags="tags"
            :other-tags-count="otherTagsCount"
          />
        </div> -->
        <profile-card
          v-for="(profileCard, id) in profileCards"
          :key="id"
          class="col-span-2 md:col-span-1"
          :icon="profileCard.icon"
          :path="profileCard.route"
        >
          <template #title>
            {{ profileCard.title }}
          </template>
          <template #description>
            {{ profileCard.description }}
          </template>
          <template #router-name>
            {{ profileCard.link }}
          </template>
        </profile-card>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import { useAuthStore } from '@stores/auth'
import { useToastStore } from '@stores/toast'
import ThreeLineHeading from '@portal/ThreeLineHeading'
// import FormTextarea from '@atoms/Form/FormTextarea'
// import FormField from '@atoms/Form/FormField'
// import FormValid from '@atoms/Form/FormValid'
import SideMenu from '@portal/SideMenu'
import ProfileCard from '@portal/ProfileCard'
// import ProfileAbout from '@portal/ProfileAbout'
// import ProfileIntroduce from '@portal/ProfileIntroduce'
// import Hashtags from '@portal/Hashtags'

import { useI18n } from 'vue-i18n'
import apiAccount from '@db/Account'
import apiHashtags from '@db/Hashtags'
import { useVuelidate } from '@vuelidate/core'
import { maxLength } from '@vuelidate/validators'

export default defineComponent({
  name: 'ProfileView',
  components: {
    ThreeLineHeading,
    // FormTextarea,
    SideMenu,
    ProfileCard
    // ProfileAbout,
    // ProfileIntroduce,
    // FormField,
    // FormValid,
    // Hashtags
  },
  setup() {
    const { t } = useI18n()
    const useAuth = useAuthStore()
    const useToast = useToastStore()
    const user = useAuth.getUser
    const isParent = useAuth.isParent
    const description = ref('')
    const tags = ref([])
    let profileCards = []
    const otherTagsCount = ref(0)

    if (isParent) {
      profileCards = [
        {
          title: t('profile.profileCards.desc.title'),
          description: t('profile.profileCards.desc.description'),
          route: '/account/about',
          link: t('profile.profileCards.desc.link'),
          icon: 'userEditSolid'
        },
        {
          title: t('profile.profileCards.hashtagsParent.title'),
          description: t('profile.profileCards.hashtagsParent.description'),
          route: '/account/tags',
          link: t('profile.profileCards.hashtagsParent.link'),
          icon: 'slackHash'
        },
        {
          title: t('profile.profileCards.settings.title'),
          description: t('profile.profileCards.settings.description'),
          route: '/account',
          link: t('profile.profileCards.settings.link'),
          icon: 'user'
        },
        {
          title: t('profile.profileCards.kids.title'),
          description: t('profile.profileCards.kids.description'),
          route: '/kids',
          link: t('profile.profileCards.kids.link'),
          icon: 'babySolid'
        },
        {
          title: t('profile.profileCards.ads.title'),
          description: t('profile.profileCards.ads.description'),
          route: '/announcments',
          link: t('profile.profileCards.ads.link'),
          icon: 'mapMarkedAltSolid'
        },
        {
          title: t('profile.profileCards.deals.title'),
          description: t('profile.profileCards.deals.description'),
          route: '/deals',
          link: t('profile.profileCards.deals.link'),
          icon: 'handshakeSolid'
        }
      ]
    } else {
      profileCards = [
        {
          title: t('profile.profileCards.desc.title'),
          description: t('profile.profileCards.desc.description'),
          route: '/account/about',
          link: t('profile.profileCards.desc.link'),
          icon: 'userEditSolid'
        },
        {
          title: t('profile.profileCards.hashtagsNanny.title'),
          description: t('profile.profileCards.hashtagsNanny.description'),
          route: '/account/tags',
          link: t('profile.profileCards.hashtagsNanny.link'),
          icon: 'slackHash'
        },
        {
          title: t('profile.profileCards.settings.title'),
          description: t('profile.profileCards.settings.description'),
          route: '/account',
          link: t('profile.profileCards.settings.link'),
          icon: 'user'
        },
        {
          title: t('profile.profileCards.attrs.title'),
          description: t('profile.profileCards.attrs.description'),
          route: '/attributes',
          link: t('profile.profileCards.attrs.link'),
          icon: 'userTagSolid'
        },
        {
          title: t('profile.profileCards.worktime.title'),
          description: t('profile.profileCards.worktime.description'),
          route: '/worktime',
          link: t('profile.profileCards.worktime.link'),
          icon: 'calendarAltSolid'
        },
        {
          title: t('profile.profileCards.cooperations.title'),
          description: t('profile.profileCards.cooperations.description'),
          route: '/deals',
          link: t('profile.profileCards.cooperations.link'),
          icon: 'handshakeSolid'
        }
      ]
    }

    const rules = {
      description: {
        maxLength: maxLength(3000)
      }
    }

    const v = useVuelidate(rules, { description })

    onMounted(async () => {
      description.value = user.user_description
      const d = await apiHashtags.readAll()
      if (d.data[0]?.values) {
        const hashtagsData = d.data[0].values
        otherTagsCount.value = hashtagsData.split(',').length - 3
        tags.value = hashtagsData.split(',').slice(0, 3)
      }
    })

    const changeDescription = async () => {
      v.value.$touch()
      if (v.value.$errors.length === 0) {
        await apiAccount
          .updateDescription({ user_description: description.value })
          .then(() => {
            useToast.add({
              type: 'success',
              title: t('profile.toast.description.success.title'),
              msg: t('profile.toast.description.success.msg'),
              icon: 'checkCircleSolid',
              duration: 5000
            })
            user.user_description = description.value
          })
          .catch(() => {
            useToast.add({
              type: 'warning',
              title: t('profile.toast.description.fail.title'),
              msg: t('profile.toast.description.fail.msg'),
              icon: 'exclamationCircleSolid',
              duration: 5000
            })
          })
      }
    }

    return {
      user,
      changeDescription,
      profileCards,
      isParent,
      tags,
      description,
      v,
      otherTagsCount
    }
  }
})
</script>
