import LayoutDefault from '@layouts/LayoutBase.vue'
import LayoutFilter from '@layouts/LayoutFilter.vue'
import LayoutNoFilter from '@layouts/LayoutNoFilter.vue'

const routes = {
  path: 'nannies',
  component: LayoutDefault,
  meta: {
    forLogged: true,
    forNanny: false,
    forParent: true
  },
  children: [
    {
      path: '',
      meta: {
        title: 'Znajdź nianię'
      },
      components: {
        default: LayoutFilter
      },
      children: [
        {
          path: '',
          name: 'nannies',
          component: () => import('../views/nannies/NanniesIndex.vue')
        }
      ]
    },
    {
      path: ':id',
      meta: {
        title: 'Profil niani'
      },
      components: {
        default: LayoutNoFilter
      },
      children: [
        {
          path: '',
          name: 'nanny',
          component: () => import('../views/nannies/NanniesProfile.vue')
        },
        {
          path: 'invite',
          name: 'inviteNanny',
          meta: {
            title: 'Moje współprace',
            forPremium: true
          },
          component: () => import('../views/nannies/InviteNanny.vue')
        }
      ]
    }
  ]
}

export default routes
