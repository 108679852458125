<template>
  <router-link :to="to" :class="btnClasses">
    <slot />
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
declare interface Boson {
  [key: string]: string | number | object
}
const elStyles: Boson = {
  default:
    'relative inline-flex w-full sm:w-auto items-center justify-center font-medium  border border-transparent transition duration-150 ease-in-out focus:outline-none focus:ring-0 shadow-sm rounded-md px-8 py-3 md:py-4 md:text-lg md:px-10  text-base',
  primary:
    'bg-primary-600 !text-white hover:bg-primary-700 focus:ring-primary-500',
  light:
    'bg-secondary-600 !text-white hover:bg-secondary-700 focus:ring-secondary-500',
  now: 'bg-warning-600 !text-white hover:bg-warning-700 focus:ring-warning-500'
}
export default defineComponent({
  name: 'HomeHeroBtn',
  props: {
    light: {
      type: String,
      default: 'primary'
    },
    to: {
      type: [String, Object],
      default: null
    },
    text: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    let btnClasses = `${elStyles.default} ${elStyles[props.light]}`
    return {
      btnClasses
    }
  }
})
</script>
