<template>
  <label class="relative inline-flex items-center">
    <input
      v-model="model"
      :name="fieldName"
      :value="option"
      :checked="option === modelValue"
      :disabled="disabled"
      :required="required"
      type="radio"
      class="h-4 w-4 cursor-pointer"
      :class="{
        'border-secondary-300 text-primary-600 focus:ring-primary-500':
          !v.$error,
        'border-danger-600 text-danger-600 focus:border-danger-500 focus:ring-red-500':
          v.$error
      }"
    />
    <div class="ml-3 flex cursor-pointer flex-col">
      <div
        v-if="label !== null"
        class="text-sm font-normal"
        :class="{
          'text-secondary-800': option === modelValue && !v.$error,
          'text-secondary-700': option !== modelValue && !v.$error,
          'text-danger-600': v.$error
        }"
      >
        {{ label }}
      </div>
      <div
        v-if="desc !== null || $slots.description"
        class="text-xs"
        :class="{
          'text-secondary-600': option === modelValue && !v.$error,
          'text-secondary-500': option !== modelValue && !v.$error,
          'text-danger-500': v.$error
        }"
      >
        {{ desc }}
      </div>
    </div>
  </label>
</template>

<script lang="ts">
import Validation from '@quarks/Validation'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AtomFormRadio',
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    option: {
      type: [String, Number, Boolean],
      required: true
    },
    label: {
      type: String,
      default: '',
      required: true
    },
    desc: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    }
  },
  setup(props, { emit }) {
    const { v, fieldSize, fieldName, useValidation } = Validation()
    const model = useValidation(props, emit)

    return {
      model,
      v,
      fieldSize,
      fieldName
    }
  }
})
</script>
