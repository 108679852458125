<template>
  <div :class="elClasses.default">
    <div :class="elClasses.container">
      <div :class="elClasses.sloganContainer">
        <h1 :class="elClasses.slogan">
          <span :class="elClasses.sloganHello">{{ titleHello }}</span>
          <span :class="elClasses.sloganFor">{{ titleFor }}</span>
        </h1>
        <p :class="elClasses.sloganDesc">
          <slot></slot>
        </p>
        <div :class="elClasses.buttons">
          <slot name="buttons"></slot>
        </div>
        <slot name="bottombuttons"></slot>
      </div>
      <div :class="elClasses.logo">
        <div :class="elClasses.logoHeight">
          <!-- <logo size="full" :logo="logo" :name="logoTitle" standalone /> -->
          <logo size="custom" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
const elStyles = {
  default: 'my-8 lg:my-24 xl:my-28',
  container:
    'w-full flex flex-col-reverse content-center lg:grid lg:grid-cols-12 lg:gap-8',
  sloganContainer: 'text-center lg:col-span-9 lg:text-left lg:pr-10 mt-8',
  slogan:
    'flex flex-col sm:flex-row sm:justify-center lg:justify-start text-4xl !font-bold tracking-tight text-secondary-800 sm:!text-5xl md:!text-6xl',
  sloganHello: 'inline',
  sloganFor: 'ml-2 text-primary-600 inline',
  sloganDesc:
    'mt-3 text-lg font-normal text-secondary-900 sm:mx-auto sm:mt-5 sm:text-xl md:mt-5 lg:mx-0 leading-7',
  buttons:
    'mt-5 sm:mt-8 flex flex-col sm:flex-row sm:justify-center lg:justify-start gap-3',
  logo: 'flex justify-center mt-0 lg:col-span-3',
  logoHeight: 'h-max'
}
import Logo from '@atoms/Logo'
export default defineComponent({
  name: 'HomeHero',
  components: {
    Logo
  },
  props: {
    titleHello: {
      type: String,
      required: true
    },
    titleFor: {
      type: String,
      required: true
    }
  },
  setup() {
    let elClasses = elStyles
    return {
      elClasses
    }
  }
})
</script>
