import LayoutDefault from '../layouts/LayoutBase.vue'

const routes = {
  path: 'start',
  component: LayoutDefault,
  meta: {
    forLogged: true,
    forNanny: true,
    forParent: true
  },
  children: [
    {
      path: '',
      name: 'start',
      meta: {
        title: 'Start'
      },
      component: () => import('../views/newstart/NewstartIndex.vue')
    }
  ]
}

export default routes
