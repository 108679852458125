export default {
  nanny: 'Konto niani',
  parent: 'Konto rodzica',
  profil: 'Profil',
  findnanny: 'Znajdź nianie',
  myads: 'Moje ogłoszenia',
  findwork: 'Znajdź pracę',
  myworks: 'Moje współprace',
  fornanny: 'Dla niani',
  forparent: 'Dla rodzica',
  logout: 'Wyloguj',
  sections: {
    main: 'Menu główne',
    settings: 'Ustawienia',
    activity: 'Aktywność'
  },
  settings: {
    start: 'Mój start',
    profil: 'Mój profil',
    account: 'Moje konto',
    kids: 'Dzieci',
    filters: 'Filtry wyszukiwania',
    attrs: 'Moje cechy',
    availability: 'Dostępność'
  },
  activity: {
    myads: 'Moje ogłoszenia',
    inbox: 'Skrzynka odbiorcza',
    notifications: 'Powiadomienia',
    cooperations: 'Współprace',
    offers: 'Oferty',
    favnannies: 'Ulubione nianie'
  },
  filters: {
    searchFilters: 'Filtry wyszukiwania',
    btn: 'Zastosuj filtry',
    showMore: 'Pokaż więcej',
    showLess: 'Pokaż mniej',
    showNannies: 'Pokazuj nianie w zasięgu',
    showAds: 'Pokazuj ogłoszenia w zasięgu',
    km: 'km',
    weight: 'Dopasowanie',
    distance: 'Odległość',
    activity: 'Ostatnio aktywna',
    added: 'Data dodania',
    mainMenu: 'Menu główne'
  }
}
