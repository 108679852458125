export default {
  searchtype: {
    fornow: {
      fulltitle: 'Niania na już',
      title: 'Niania na już',
      description:
        'Wybierz ten rodzaj szukania jeśli potrzebujesz pilnie znaleźć nianie na już!'
    },
    permanently: {
      fulltitle: 'Niania na stałe',
      title: 'Na stałe',
      description:
        'Wybierz ten rodzaj szukania jeśli szukasz niani do pracy na stałe w ciągu dnia.'
    },
    hours: {
      fulltitle: 'Niania na kilka godzin',
      title: 'Na kilka godzin',
      description:
        'Wybierz ten rodzaj szukania jeśli szukasz niani do pracy na kilka godzin dziennie.'
    },
    evening: {
      fulltitle: 'Niania na wieczór',
      title: 'Na wieczór',
      description:
        'Wybierz ten rodzaj szukania jeśli szukasz niani na wieczorne wyjście.'
    }
  }
}
// fornow, permanently, hours, evening
